import { useContext } from "react";
import { InstalmentTemplateResource } from "@gocardless/api/dashboard/types";
import { useInstalmentTemplateSelf } from "@gocardless/api/dashboard/instalment-template";
import { Logger } from "src/common/logger";
import { showError } from "src/common/utils";

import { GlobalState } from ".";

export const InstalmentTemplateInitialiser = () => {
  const { billingRequestFlow, setInstalmentTemplate, setError } =
    useContext(GlobalState);

  const log = Logger("InstalmentTemplateInitialiser", {
    instalmentTemplateId: billingRequestFlow?.config?.instalment_template?.id,
  });

  useInstalmentTemplateSelf(
    billingRequestFlow?.config?.instalment_template?.id ?? "",
    !!billingRequestFlow?.config?.instalment_template?.id,
    {
      onSuccess(data) {
        log({ message: "received instalment template, setting in state" });
        setInstalmentTemplate(
          data.instalment_templates as InstalmentTemplateResource
        );
      },
      onError(error) {
        log({ error: error });
        showError(error, setError, "InstalmentTemplateInitialiser");
      },
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return <div data-testid="instalment-template-initialiser" />;
};

export default InstalmentTemplateInitialiser;
