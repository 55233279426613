import LoadingGif from "src/components/shared/LoadingGif";
import Cookies from "js-cookie";
import getAppConfig from "src/common/config";

const ThreatMetrixIframe = ({ payerOrgId }: { payerOrgId: string }) => {
  const sessionId = Cookies.get(
    getAppConfig().client.rhinoState.rhinoStateName
  );

  const zebraBaseUrl = getAppConfig().client.urls.zebraIframe;

  const fullZebraUrl = `${zebraBaseUrl}?session-id=${sessionId}&payer-org-id=${payerOrgId}`;

  return (
    <>
      <LoadingGif />
      <iframe src={fullZebraUrl} title="Zebra" width={0} height={0} />
    </>
  );
};

export default ThreatMetrixIframe;
