import { useContext, useEffect } from "react";
import { BankAuthorisationSelfResponseBody } from "@gocardless/api/dashboard/types";
import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import { Logger } from "src/common/logger";
import api from "@gocardless/api/utils/api";
import { showError } from "src/common/utils";

import { GlobalState } from ".";

const BankAuthorisationInitialiser = ({
  callIfBankAuthPresent,
}: {
  callIfBankAuthPresent?: boolean;
}) => {
  const {
    billingRequestId,
    billingRequest,
    bankAuthorisation,
    setBankAuthorisation,
    setError,
    shouldCreateNewBankAuth,
  } = useContext(GlobalState);

  const log = Logger("BankAuthorisationInitialiser", {
    billing_request_id: billingRequestId,
  });

  useEffect(() => {
    // Don't initialise if we have a bankAuthorisation
    // callIfBankAuthPresent: to avoid stale data for bank auth in bank-wait (IBP DE)
    if (bankAuthorisation && !callIfBankAuthPresent) {
      return;
    }

    // Don't initialise if we don't have a billingRequest
    if (!billingRequest) {
      return;
    }

    // Don't initialise if a new bank authorisation is required
    if (shouldCreateNewBankAuth) {
      return;
    }

    const bankAuthorisationId =
      billingRequest.links?.bank_authorisation || bankAuthorisation?.id;

    if (!bankAuthorisationId) {
      return;
    }

    api.API.get(
      getEndpointURL(Endpoint.BankAuthorisationSelf, { bankAuthorisationId })
    )
      .then((resp) => resp.json())
      .then((data: BankAuthorisationSelfResponseBody) => {
        log({
          message: "fetched bank authorisation, setting in state",
        });
        setBankAuthorisation(data.bank_authorisations);
      })
      .catch((error) => {
        log({
          error: error,
        });
        showError(error, setError, "BankAuthorisationInitialiser");
      });
  }, [
    billingRequestId,
    billingRequest,
    setBankAuthorisation,
    setError,
    bankAuthorisation,
    callIfBankAuthPresent,
    shouldCreateNewBankAuth,
  ]);

  return <div data-testid="bank-authorisation-initialiser" />;
};

export default BankAuthorisationInitialiser;
