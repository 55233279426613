import { useRhinoBrfSelf } from "@gocardless/api/dashboard/rhino-brf";
import APICache from "@gocardless/api/utils/api";
import { getItem, setItem } from "src/legacy-ui/local-storage";

export const useRhinoState = (billingRequestFlowId?: string) => {
  // The presence of the org-id represents that the payer should be tracked
  const orgKey = `${billingRequestFlowId}-rhino-brf-org-id`;
  const calledKey = `${billingRequestFlowId}-rhino-brf-called`;

  const gotResponse = getItem(calledKey);

  const APIAccessToken = APICache.accessToken;

  let shouldMakeRequest: boolean;

  if (!APIAccessToken) {
    shouldMakeRequest = false;
  } else {
    shouldMakeRequest = !gotResponse;
  }

  const billingRequestFlowIdQuery =
    shouldMakeRequest && billingRequestFlowId
      ? billingRequestFlowId
      : "any string, this will never be sent";

  const { data: rhinoBrfResponse } = useRhinoBrfSelf(
    { brf_id: billingRequestFlowIdQuery },
    shouldMakeRequest
  );

  if (rhinoBrfResponse) {
    setItem(calledKey, "true");

    if (rhinoBrfResponse.org_id) {
      setItem(orgKey, rhinoBrfResponse.org_id);
    }
  }
};
