import { useContext } from "react";
import { NextPage } from "next";
import ErrorsBoundary from "src/components/shared/ErrorsBoundary";
import LoadingBoundary from "src/state/StateInitialiser";
import { Router, Routes } from "src/components/shared/Router";
import LoadingGif from "src/components/shared/LoadingGif";
import { SessionInitialiser } from "src/state/SessionInitialiser";
import BillingRequestInitialiser from "src/state/BillingRequestInitialiser";
import Layout from "src/components/shared/Layout";
import { GlobalState } from "src/state";
import ResourceInitialiserForRoute from "src/state/ResourceInitialiserForRoute";

const Page: NextPage<{}> = () => {
  const { billingRequest } = useContext(GlobalState);

  return (
    <Layout>
      <ErrorsBoundary>
        <SessionInitialiser />
        <BillingRequestInitialiser />

        <ResourceInitialiserForRoute
          page={Routes.Flow}
          billingRequest={billingRequest}
        />

        <LoadingBoundary
          requiredResources={{
            billingRequest: true,
          }}
        >
          <Router>
            <LoadingGif />
          </Router>
        </LoadingBoundary>
      </ErrorsBoundary>
    </Layout>
  );
};

export default Page;
