const isStorageWritable = () => {
  let isWritable = false;
  // Safari in private browsing mode disables localStorage
  // Fall back to just storing it in memory
  try {
    if ("localStorage" in window) {
      window.localStorage.setItem("gc.storage.test", "ok");
      window.localStorage.removeItem("gc.storage.test");
      isWritable = true;
    }
  } catch {
    /* empty */
  }

  return isWritable;
};

const MEMORY_STORAGE: { [key: string]: object | string | undefined } = {};

export const setItem = (key: string, value: object | string | undefined) => {
  if (isStorageWritable()) {
    window.localStorage.setItem(key, JSON.stringify(value));
  } else {
    MEMORY_STORAGE[key] = value;
  }
};

export const getItem = (key: string) => {
  if (isStorageWritable()) {
    return JSON.parse(window.localStorage.getItem(key) || '""') || null;
  }
  return key in MEMORY_STORAGE ? MEMORY_STORAGE[key] : null;
};

export const removeItem = (key: string) => {
  if (isStorageWritable()) {
    return window.localStorage.removeItem(key);
  }
  delete MEMORY_STORAGE[key];
};
