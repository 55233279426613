import { useContext, useEffect } from "react";
import { PaylinkSelfResponseBody } from "@gocardless/api/dashboard/types";
import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import { Logger } from "src/common/logger";
import { showError } from "src/common/utils";
import api from "@gocardless/api/utils/api";

import { GlobalState } from ".";

export const PaylinkInitialiser = () => {
  const { billingRequestFlow, paylink, setPaylink, setError } =
    useContext(GlobalState);

  useEffect(() => {
    const log = Logger("PaylinkInitialiser", {
      paylinkId: billingRequestFlow?.config?.paylink?.id,
    });
    // do not initialise if we already have paylink loaded
    if (paylink) {
      return;
    }
    // do not initialise if we do not have paylinkId
    if (!billingRequestFlow?.config?.paylink?.id) {
      return;
    }

    api.API.get(
      getEndpointURL(Endpoint.PaylinkSelf, {
        paylinkId: billingRequestFlow.config.paylink.id,
      })
    )
      .then((response) => response.json())
      .then((data: PaylinkSelfResponseBody) => {
        if (data.paylinks) {
          setPaylink(data.paylinks);
          log({ message: "received paylink, setting in state" });
        } else {
          log({ message: "no paylink received" });
        }
      })
      .catch((error) => {
        log({ error: error });
        showError(error, setError, "PaylinkInintialiser");
      });
  }, [billingRequestFlow, paylink, setPaylink, setError]);

  return <></>;
};

export default PaylinkInitialiser;
