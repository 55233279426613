import { useContext, useEffect } from "react";
import { useLocalStorage } from "react-use";
import LoadingGif from "src/components/shared/LoadingGif";
import { TMX_TRACKING_WAIT_PERIOD } from "src/common/consts";
import { requiresAction } from "src/common/utils";
import {
  ActionType,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import ThreatMetrixIframe from "src/components/pages/ThreatMetrix/ThreatMetrixIframe";

import { GlobalState } from ".";

const isInstitutionRequired = (billingRequest?: BillingRequestResource) => {
  return (
    !billingRequest ||
    (billingRequest &&
      requiresAction({
        billingRequest,
        actionType: ActionType.SelectInstitution,
      }))
  );
};

interface LoadingBoundaryType {
  requiredResources: {
    billingRequest?: boolean;
    availableInstitutions?: boolean;
    bankAuthorisation?: boolean;
    selectedInstitution?: boolean;
    bankDetailsConfirmed?: boolean;
    mandateMigration?: boolean;
  };
  children?: React.ReactNode;
}

const LoadingBoundary = ({
  requiredResources,
  children,
}: LoadingBoundaryType) => {
  const {
    billingRequestFlow,
    error,
    billingRequest,
    availableInstitutions,
    bankAuthorisation,
    selectedInstitution,
    mandateMigration,
  } = useContext(GlobalState);

  const loadingSession = !(billingRequestFlow || mandateMigration || error);
  const loadingResources =
    (requiredResources.billingRequest && !billingRequest) ||
    (requiredResources.availableInstitutions && !availableInstitutions) ||
    (requiredResources.bankAuthorisation && !bankAuthorisation) ||
    (requiredResources.selectedInstitution &&
      !selectedInstitution &&
      isInstitutionRequired(billingRequest)) ||
    (requiredResources.mandateMigration && !mandateMigration);

  const billingRequestFlowId = billingRequestFlow
    ? billingRequestFlow.id
    : "Still loading `billingRequestFlow`...";

  if (!billingRequestFlowId) {
    // Workaround because our schemas are wrong, this should never be reached
    throw Error("Billing request flow without an ID!");
  }

  // The presence of the org-id represents that the payer should be tracked
  const [localOrgId] = useLocalStorage(
    `${billingRequestFlowId}-rhino-brf-org-id`,
    ""
  );

  const shouldBeTracked = localOrgId && !(loadingSession || loadingResources);

  // This represents that the payer has seen the TMX Iframe (i.e. has been tracked)
  const [hasBeenTracked, setHasBeenTracked] = useLocalStorage(
    `${billingRequestFlowId}-ramltoolkit-done`,
    false
  );

  const showTMXIframe = shouldBeTracked && !hasBeenTracked;

  useEffect(() => {
    const startTimerToEndTMXIframe =
      !(loadingSession || loadingResources) && showTMXIframe;
    if (startTimerToEndTMXIframe) {
      const timer = setTimeout(() => {
        setHasBeenTracked(true);
      }, TMX_TRACKING_WAIT_PERIOD);

      return () => clearTimeout(timer);
    }
    return;
  }, [loadingResources, loadingSession, showTMXIframe]);

  if (loadingSession || loadingResources) {
    return <LoadingGif />;
  } else if (showTMXIframe) {
    return <ThreatMetrixIframe payerOrgId={localOrgId} />;
  } else {
    return <> {children} </>;
  }
};

export default LoadingBoundary;
