import { useContext, useEffect } from "react";
import { BillingRequestSelfResponseBody } from "@gocardless/api/dashboard/types";
import { Logger } from "src/common/logger";
import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import api from "@gocardless/api/utils/api";
import { findBestMatchLocale, showError } from "src/common/utils";
import { Locale, useI18n } from "src/common/i18n";
import {
  customiseForScheme,
  Scheme,
} from "src/common/schemeCustomisations/index";

import { GlobalState } from ".";

const BillingRequestInitialiser = () => {
  const {
    billingRequestId,
    billingRequest,
    setBillingRequest,
    setSelectedInstitution,
    setError,
  } = useContext(GlobalState);

  const log = Logger("BillingRequestInitialiser", {
    billing_request_id: billingRequestId,
  });

  const [, setLocale] = useI18n();

  useEffect(() => {
    // Don't initialise if we have a billingRequest
    if (billingRequest) {
      return;
    }

    // Don't try if we have no ID
    if (!billingRequestId) {
      return;
    }

    api.API.get(
      getEndpointURL(Endpoint.BillingRequestSelf, { billingRequestId })
    )
      .then((resp) => resp.json())
      .then((data: BillingRequestSelfResponseBody) => {
        log({
          message: "received billing request, setting billing request in state",
        });

        // we need to set the BRF locale to match the user preferred locale
        // from their browser. however we should not do this for payments or
        // mandates of the GBP currency
        const mandateScheme = data.billing_requests?.mandate_request
          ?.scheme as Scheme;
        const paymentScheme = data.billing_requests?.payment_request
          ?.scheme as Scheme;
        const shouldMatchLocale =
          (mandateScheme &&
            customiseForScheme({
              scheme: mandateScheme,
              key: "billing-request.match-default-locale",
            })) ||
          (paymentScheme &&
            customiseForScheme({
              scheme: paymentScheme,
              key: "billing-request.match-default-locale",
            }));

        if (data.billing_requests && shouldMatchLocale) {
          const defaultLocale = findBestMatchLocale() as Locale;
          setLocale(defaultLocale);
        }
        const institution = data.billing_requests?.resources?.institution;
        if (institution) setSelectedInstitution(institution);
        setBillingRequest(data.billing_requests);
      })
      .catch((error) => {
        log({
          error: error,
        });
        showError(error, setError, "BillingRequestInitilaiser");
      });
  }, [
    billingRequestId,
    billingRequest,
    setBillingRequest,
    setError,
    setLocale,
  ]);

  return <></>;
};

export default BillingRequestInitialiser;
