import { Routes } from "src/components/shared/Router";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { isSepaIBPScheme } from "src/common/utils";

import PlanInitialiser from "./PlanInitialiser";
import InstalmentTemplateInitialiser from "./InstalmentTemplateInitialiser";
import PaylinkInitialiser from "./PaylinkInitialiser";
import BankAuthorisationInitialiser from "./BankAuthorisationInitialiser";

enum Resource {
  Plan = "Plan",
  InstalmentTemplate = "InstalmentTemplate",
  Paylink = "Paylink",
  BankAuthorisation = "BankAuthorisation",
  ConsentParameters = "ConsentParameters",
}

const routeResoureConfig: Partial<Record<Routes, Resource[]>> = {
  [Routes.BankConfirm]: [
    Resource.Plan,
    Resource.Paylink,
    Resource.BankAuthorisation,
  ],
  [Routes.BankSelect]: [
    Resource.Plan,
    Resource.InstalmentTemplate,
    Resource.Paylink,
  ],
  [Routes.CollectBankAccount]: [
    Resource.Plan,
    Resource.InstalmentTemplate,
    Resource.Paylink,
  ],
  [Routes.CollectCustomerDetails]: [
    Resource.Plan,
    Resource.InstalmentTemplate,
    Resource.Paylink,
  ],
  [Routes.CollectAmount]: [
    Resource.Plan,
    Resource.InstalmentTemplate,
    Resource.Paylink,
  ],
  [Routes.BankWait]: [Resource.BankAuthorisation],
  [Routes.Flow]: [Resource.BankAuthorisation],
};

const ResourceInitialiserForRoute = ({
  page,
  billingRequest,
}: {
  page: Routes;
  billingRequest?: BillingRequestResource;
}) => (
  <>
    {(routeResoureConfig[page] ?? []).map((resource) => {
      switch (resource) {
        case Resource.Plan:
          return <PlanInitialiser key={resource} />;
        case Resource.InstalmentTemplate:
          return <InstalmentTemplateInitialiser key={resource} />;
        case Resource.Paylink:
          return <PaylinkInitialiser key={resource} />;
        case Resource.BankAuthorisation:
          return billingRequest && isSepaIBPScheme(billingRequest) ? (
            <BankAuthorisationInitialiser
              key={resource}
              callIfBankAuthPresent={page === Routes.BankWait}
            />
          ) : null;
        default:
          return null;
      }
    })}
  </>
);

export default ResourceInitialiserForRoute;
