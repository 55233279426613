import { useContext, useEffect } from "react";
import {
  PlanResource,
  PlanSelfResponseBody,
} from "@gocardless/api/dashboard/types";
import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import { Logger } from "src/common/logger";
import { showError } from "src/common/utils";
import api from "@gocardless/api/utils/api";

import { GlobalState } from ".";

export const PlanInitialiser = () => {
  const { billingRequestFlow, plan, setPlan, setError } =
    useContext(GlobalState);

  const log = Logger("PlanInitialiser", {
    planId: billingRequestFlow?.config?.plan?.id,
  });

  useEffect(() => {
    // do not initialise if we already have plan loaded
    if (plan) {
      return;
    }
    // do not initialise if we do not have planId
    if (!billingRequestFlow?.config?.plan?.id) {
      return;
    }

    api.API.get(
      getEndpointURL(Endpoint.PlanSelf, {
        planId: billingRequestFlow?.config?.plan?.id,
      })
    )
      .then((response) => response.json())
      .then((data: PlanSelfResponseBody) => {
        log({ message: "received plan, setting in state" });
        setPlan(data.plans as PlanResource);
      })
      .catch((error) => {
        log({ error: error });
        showError(error, setError, "PlanInintialiser");
      });
  }, [billingRequestFlow, plan, setPlan, setError]);

  return <div data-testid="plan-initialiser" />;
};

export default PlanInitialiser;
